.owl-nav button.owl-prev img:nth-child(2) {
    display: none;
}

.owl-nav button.owl-prev:hover img:nth-child(2) {
    display: block;
}

.owl-nav button.owl-prev:hover img:nth-child(1) {
    display: none;
}

.owl-nav button.owl-next:hover img:nth-child(2) {
    display: block;
}

.owl-nav button.owl-next:hover img:nth-child(1) {
    display: none;
}

.owl-nav button.owl-next img:nth-child(2) {
    display: none;
}

.rdrDateDisplay {
    margin-top: 40px;
    position: relative;
}

.rdrDateDisplayItem input {
    color: var(--black-dark-800) !important;
    font-weight: 500 !important;
}

.rdrDateDisplay:before {
    content: "From";
    position: absolute;
    left: 0;
    top: -28px;
    font-size: 14px;
    color: var(--black-dark-600);
    font-weight: 500;
}

.rdrDateDisplay {
    margin-top: 40px !important;
    position: relative;
}

.rdrDateDisplay:after {
    content: "To";
    position: absolute;
    left: 327px;
    top: -28px;
    font-size: 14px;
    color: var(--black-dark-600);
    font-weight: 500;
}

.rdrMonthAndYearPickers select {
    font-weight: 500;
    color: var(--black-dark-800) !important;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span {
    color: white !important;
}

.rdrDayNumber span {
    font-size: 14px;
    color: var(--black-dark-800) !important;
    font-weight: 500;
}



.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
    background-color: var(--primary-color) !important;
}

.rdrDayHovered span {
    border-color: var(--primary-color) !important;
}

.source-table {
    width: 100%;
}

.toggle-group button:nth-child(1) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
}

.toggle-group button:nth-child(2) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.toggle-group button.active+button {
    border-left-color: var(--primary-color);
}

.toggle-group button.active,
.toggle-group button.active:hover {
    background: var(--Blue-100);
    color: var(--primary-color);
    font-weight: 500;
    border-color: var(--primary-color);
}



.toggle-group {
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;
}

.toggle-group button {
    border: 1px solid #D6D6E0;
    background: transparent;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: var(--black);
    padding: 6px 20px;
    border-radius: 4px;
    overflow: hidden;
    white-space: nowrap;
}

.toggle-group button:hover {
    background: #F6F6F6;
    color: var(--black-dark-800);

}

.map.position-relative {
    flex: 0 0 260px;
}

.Pipeline-Analysis-table .map_list {
    justify-content: flex-start;
    overflow: auto;
}

.pipline-maplist ul {
    margin-left: 0 !important;
    margin-bottom: 0;
}

.pipline-maplist {
    padding-left: 20px;
    min-width: 350px;
}

.an-title .download-btn-group .assigned-drop button.outline-drop span {
    text-align: left;
}

.an-title .download-btn-group .assigned-drop button.outline-drop svg {
    margin-left: auto;
}

.an-title .download-btn-group .assigned-drop button.outline-drop span:nth-child(1) {
    max-width: 107px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 4px;
    font-size: 14px !important;
}

.an-title .download-btn-group .assigned-drop button.outline-drop:hover {
    border-color: var(--black) !important;
}

.owl-theme .owl-nav .disabled {
    opacity: 0;
}

.owl-theme .owl-nav {
    margin: 0 !important;
}

.conversion-table .table tbody {
    max-height: 279px;
    min-height: 279px;

}

.booking-eoi-table tbody tr td:not(fist-child, last-child) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ded-scroll-table tbody {
    max-height: 198px !important;
    min-height: 198px !important;
}

.an-title .download-btn-group .assigned-drop button.outline-drop.active {
    background: var(--Blue-100);
    color: var(--primary-color);
    border-color: var(--primary-color) !important;
}

.an-title .download-btn-group .assigned-drop button.outline-drop.active svg path {
    fill: var(--primary-color);
}

.pipline-maplist li span.new {
    background: #F0F6FF !important;
}

.map_list ul li span.no {
    background: #A4C6FF;
}

.map_list ul li span.process {
    background: #7BADFF;
}

.map_list ul li span.schedule {
    background: #5294FF;
}

.map_list ul li span.svcompleted {
    background: #2478FF;
}

.map_list ul li span.eoicompleted {
    background: #0062FF;
}

.map_list ul li span.booking {
    background: #002D75;
}

.map_list ul li span.dead {
    background: #CCE0FF;
}

.map.position-relative {
    margin-top: 15px;

}

.map_list ul li span.new {
    background: #0062FF;
}

.map_list ul li span.hot {
    background: #82C43C;
}

.map_list ul li span.warm {
    background: #FF974A;
}

.map_list ul li span.cold {
    background: #50B5FF;
}

.map_list ul li span.dead-lead {
    background: #FC5A5A;
}

.map_list ul li {
    letter-spacing: -0.02em;
    color: #252525;
    font-size: 12px;
    line-height: 32px;
    margin-bottom: 8px;
    font-weight: 500;
    display: flex;
    align-items: center;
    white-space: nowrap;

}

.map_list {
    height: 100%;
}

.map_list ul {
    margin-left: 92px;
}

.map_list ul li span {
    width: 12px;
    height: 12px;
    display: inline-block;
    margin-right: 12px;
    flex: 0 0 12px;
}

.map_list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}



.table-card .table tfoot tr td:first-child {
    position: sticky;
    left: 0;
    text-transform: uppercase;
}

.table-card table thead {
    position: sticky !important;
    top: 0;
}

.an-title .download-btn-group .assigned-drop .dropdown-menu .dropdown-item {
    padding: 4px 10px;
    letter-spacing: 0.5px;
    font-size: 12px;
    font-weight: 500;
    color: var(--black) !important;
}

.an-title .download-btn-group .assigned-drop .dropdown-menu .dropdown-item label {
    font-size: 12px;
    font-weight: 500;
    color: #44444F;
    display: block;
}

.an-title .download-btn-group .assigned-drop .dropdown-menu .dropdown-item input {
    margin-right: 12px;
}

.an-title .download-btn-group .assigned-drop .dropdown-menu .dropdown-item .form-check {
    display: block;
    width: 100%;
    cursor: pointer;
}



.result-box .box .info-alert button {
    padding: 0;
    line-height: 18px;
    margin-top: -3px;
}

.an-bords {
    display: flex;
    flex: 0 0 calc(100% - 64px);
    width: calc(100% - 64px);

}

.an-sidebar {
    position: relative;
    box-shadow: inset -1px 0px 0px #E2E2EA;
    max-width: 224px;
    flex: 0 0 224px;
    padding-top: 26px;
    padding-left: 8px;
    padding-right: 8px;
    transition: 0.5s all;
}

.an-sidebar h1 {
    padding-left: 8px;
    margin-bottom: 24px;

}

.an-bottom {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    border-top: 1px solid #F1F1F5;
    padding: 18px 0;
}

.an-bottom button {
    width: 30px;
    height: 30px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border: 1px solid #DEDEDE;
    background: transparent;
    display: table;
    margin-left: auto;
    margin-right: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.an-bottom button:hover svg path {
    stroke: var(--primary-color);
}

.an-sidebar .an-menu {
    margin: 0;
    padding: 0;
}

.an-sidebar .an-menu li a {
    padding: 8px 16px;
    display: block;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: var(--black);
    letter-spacing: 0.5px;

}

.an-sidebar .an-menu li {
    margin-bottom: 4px;
}

.an-sidebar .an-menu li a.active,
.an-sidebar .an-menu li a:hover {
    background: var(--Blue-100);
    color: var(--primary-color);
}

.close-and-expand .an-sidebar {
    transform: translateX(-325px);
    z-index: 0;
    width: 0;
    flex: 0;
}


.an-main-area {
    width: 100%;
    flex: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: var(--black-dark-100);

}


.table-card {

    overflow: hidden;

}

.an-table-area {
    padding: 0 32px;
    margin-bottom: 32px;
}

.table-card .table {
    margin-bottom: 0;
}

.table-card {
    background: #FFFFFF;
    border: 1px solid #D6D6E0;
    border-radius: 6px;
    height: 394px;
}

.table-header {
    padding: 16px 32px;
    border-bottom: 1px solid #DEDEDE;
}

.table-card tr th {
    text-transform: uppercase;
    color: rgba(105, 105, 116, 0.8);
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.035em;
    line-height: 24px;
}

.table-card table thead,
.table-card table thead tr th {
    background: white !important;
    border: 0 !important;
}

.table-card table {
    padding-left: 30px;
}

.table-card table tr td {
    border: 0 !important;
}

.table-card .table tbody tr td:nth-child(2) {
    background: transparent;
    position: unset !important;
}


.table-card .table tbody tr td {

    font-size: 16px !important;
    line-height: 24px;


    letter-spacing: -0.02em;
}

.table-card .table tbody tr td {
    font-weight: 500 !important;
    font-family: var(--roboto) !important;
    letter-spacing: -0.02em;

}

.table-card .table tbody tr td:nth-child(1) {
    font-weight: 500 !important;
    padding-left: 32px !important;
    font-family: var(--worksans) !important;

}

.table-card .table thead tr th:nth-child(1) {
    padding-left: 32px !important;


}


.table-card .table-striped tbody tr:nth-of-type(even) td {
    background-color: white;
}

.table-card .table-striped tbody tr:nth-of-type(odd) td {
    background: #F9F9FB;
}

.record-notfound {
    margin-top: 40px;
}

.dead-t-header h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    letter-spacing: 0.1px;
    color: #44444F;
    display: block;
    padding-left: 16px;
    padding-bottom: 5px;
    border-bottom: 1px solid #DEDEDE;
    margin-bottom: 20px;
    margin-top: 6px;
}

button.expedn-btn {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 10%);
    border: 1px solid #DEDEDE;
    background: transparent;
    display: table;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
}

button.expedn-btn span {
    opacity: 0;
    font-size: 0;
    transition: 0.5s all;
    color: #696974;
    font-weight: 400;
    line-height: 18px;
}

.dead-lead-table button.expedn-btn {
    position: absolute;
    right: 20px;
    top: 28px;
    background: white;
}

button.expedn-btn:hover span {
    opacity: 9;
    font-size: 12px;
    margin-right: 10px;
}

.table-header h2 {
    line-height: 32px;
    white-space: nowrap;
}

.an-title .download-btn-group .assigned-drop button.outline-drop {

    font-size: 12px;
    font-weight: 500;
    color: var(--black);
    padding: 3px 13px !important;
    background: transparent;
    letter-spacing: 0.1px;
    border: 1px solid var(--black-dark-500) !important;
    font-size: calc(var(--value) * 7);
    line-height: calc(var(--value) * 11);
    padding: calc(var(--value) * 2) calc(var(--value) * 4);
    border-radius: calc(var(--value) * 2);
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.an-title .download-btn-group .assigned-drop .dropdown-menu::-webkit-scrollbar {
    background-color: white;
}

.an-title .download-btn-group .assigned-drop .dropdown-menu {
    width: 200px !important;
    padding: 8px 0px !important;
    overflow: hidden;
}

.an-title .download-btn-group .assigned-drop .dropdown-menu a {
    padding: 4px 8px !important;
}

.scroll-table .table tbody tr td:nth-child(2) {
    margin-right: 0 !important;
}

.scroll-table::-webkit-scrollbar-button:vertical:start:decrement,
.ded-scroll-table::-webkit-scrollbar-button:vertical:start:decrement {
    height: 48px !important;
    display: block !important;
    color: #d9d9d9;
}

.booking-eoi-table .scroll-table::-webkit-scrollbar-button:vertical:end:decrement,
.userpipeline .scroll-table::-webkit-scrollbar-button:vertical:end:decrement,
.conversion-table .scroll-table::-webkit-scrollbar-button:vertical:end:decrement {
    height: 0 !important;
}

.scroll-table::-webkit-scrollbar-button:vertical:end:decrement {
    height: 55px !important;
    display: block !important;
    color: #d9d9d9;
}

.result-box .box .info-alert svg {
    margin-top: -4px;
}

.an-title {
    padding: 20px 32px 20px 32px;
    border-bottom: 1px solid rgba(226, 226, 234, 0.5);
    margin-bottom: 24px;
    background-color: white;
    z-index: 2;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.an-title {
    padding: 20px 26px 20px 26px;
    border-bottom: 1px solid rgba(226, 226, 234, 0.5);
    margin-bottom: 24px;
}

.custom-btns-group {
    background: #FFFFFF;
    border: 1px solid var(--black-dark-500);
    border-radius: 6px;
    overflow: hidden;
}

.custom-btns-group button {
    border: 0;
    background: white;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: var(--black);
    padding: 6px 8px;
    white-space: nowrap;
}

.custom-btns-group button+button {
    border-left: 1px solid var(--black-dark-500);
}

.custom-btns-group button:hover {
    background: #F6F6F6;
    color: var(--black-dark-800);
    font-weight: 500;
    /* background: var(--Blue-100);
    color: var(--primary-color); */

}

.custom-btns-group button:hover svg path {
    stroke: var(--black-dark-800);
}

.custom-btns-group {
    margin-left: 16px;
}

.scroll-table thead tr th:not(:first-child),
.scroll-table tbody tr td:not(:first-child) {

    padding-left: 20px !important;
    padding-right: 20px !important;
}

.scroll-table tfoot tr td:not(:first-child),
.scroll-table tbody tr td:not(:first-child) {
    font-family: var(--roboto);
    font-size: 16px;
    letter-spacing: -0.01em;
}

.scroll-table tfoot tr td:not(:first-child) {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.scroll-table:not(.user-scroll-table) tr th,
.scroll-table:not(.user-scroll-table) tr td {
    width: 130px;
}

.custom-btns-group button.active svg path {
    stroke: var(--primary-color);
}

.custom-btns-group button.active {
    font-weight: 600;
    background: var(--Blue-100);
    color: var(--primary-color);

}

.result-box {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #DEDEDE;
    background-color: var(--white);
    border-radius: 6px;
    margin: 0 32px 32px 32px;
    padding-bottom: 0;
    padding: 16px 0px;
}

.table-card .table tfoot tr td {
    padding-top: 20px;
    padding-bottom: 12px;
}

.result-box h3 {
    font-size: 14px;
    color: #5F6368;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 2px;
    font-family: var(--popins);
    white-space: nowrap;
}

.result-box p {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0;
    color: var(--black);
    font-family: var(--roboto);
    margin-top: 2px;
    display: inline-block;
}


.result-box .box {
    padding: 0px 16px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    max-width: 200px;
    flex: 0 0 200px;
}



.result-box .box .info-alert {
    margin-left: 5px;
}

.info-alert {
    position: relative;
}

/* .info-msg {
    position: absolute;
    z-index: -1;
    width: 156px;
    padding: 8px 12px;
    background: #162C50;
    border-radius: 6px;
    color: white;
    opacity: 0;
    transition: 0.25s all;
    transform: translate(-45%, 28px);
    cursor: pointer;

} */
.an-title .download-btn-group .assigned-drop .dropdown-menu .dropdown-item.active,
.an-title .download-btn-group .assigned-drop .dropdown-menu .dropdown-item:active {
    background: #f8f9fa !important;
}


.info-msg h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #44444F;
    margin-bottom: 4px;
}

.info-msg p+p {
    margin-top: 4px;
}

.info-msg p,
.info-msg {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: white;
    padding-bottom: 0px;
    margin-bottom: 0;
}

/* .info-msg:before {
    content: " ";
    width: 30px;
    height: 20px;
    background: #162c50;
    position: absolute;
    left: 64px;
    top: -14px;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
} */

/* .info-alert:hover .info-msg {
    opacity: 9;
    transform: translate(-45%, 20px);
    z-index: 9;
} */

.pipeline {
    margin-bottom: 32px;
    padding: 0 25px;
}

.pipeline img {
    max-width: 100%;
}






.qualification-source-table .source-table .table-card {
    width: 100%;
}


.dead-lead-table .table-header,
.lead-activity .table-header {
    padding: 16px;
}

.dead-lead-table .table tbody tr td {
    font-size: 14px !important;
    padding: 6px 17px !important;
    line-height: 18px;
    color: var(--black-dark-800);
}

.dead-lead-table .table tbody tr td:nth-child(1) {
    padding-left: 16px !important;
    font-weight: 500 !important;
    font-family: var(--roboto) !important;
    white-space: break-spaces;
    display: block;
    letter-spacing: -0.02em;
}


.dead-lead-table .table tbody tr td:nth-child(2) {
    font-weight: 600 !important;
}

.dead-lead-table .table tbody tr:last-child td {
    padding-top: 6px !important;
}

.dead-lead-table .table thead tr th:nth-child(1) {
    padding-left: 16px !important;

}

.dead-lead-table .table tbody tr td:nth-child(2) {
    text-align: right;
}

.dead-count {
    padding: 22px 16px;
    position: relative;
}

.dead-count h5 {
    font-weight: 600;
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 0;
    position: relative;
    padding-left: 16px;
}

.dead-count h5:before {
    content: " ";
    position: absolute;
    left: 0;
    top: -7px;
    bottom: 0;
    height: 50px;
    width: 4px;
    border-radius: 0px 4px 4px 0px;
    background: var(--primary-color);
}

.total-lead-counts {
    padding: 16px;
}

.total-lead-counts .total-lead-block h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #696974;
    margin-bottom: 4px;
}

.total-lead-counts .total-lead-block p {
    margin-bottom: 0;
    color: var(--black);
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
}

.total-lead-block+.total-lead-block {
    margin-top: 24px;
}

.Pipeline-Analysis {
    padding: 0 25px;
    margin-bottom: 32px;
}


.Pipeline-Analysis-table .table tbody tr:last-child td {
    padding-top: 8px !important;
    font-weight: 600 !important;
}

.Employee-Pipeline-Analysis {
    padding: 0 32px;
    margin-bottom: 32px;
}

.qualification-source-table {
    margin-bottom: 32px;
    padding: 0 32px;
}

.an-title .download-btn {
    min-width: 138px;
    font-size: 12px;
    font-weight: 500;
    color: var(--black);
    padding: 6px 10px !important;
    border: 1px solid var(--black-dark-500);
    background: white;
    margin-left: 16px;
    border-radius: 4px;
    text-align: left;
}

.scroll-table .table thead tr th:nth-child(1),
.scroll-table .table tbody tr td:nth-child(1) {
    text-align: left;
    width: 210px;
    min-width: 210px;
    max-width: 210px;
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.custom-date-modal .modal-dialog {
    max-width: 923px;
}

.custom-date-modal .modal-dialog .close {
    position: absolute;
    right: 15px;
    top: 15px;
}

.an-title .download-btn svg {
    margin-right: 8px;
}

.an-title .download-btn:focus {
    border-color: var(--primary-color);
}

.an-main-area .table tbody:not(thead, tfoot) tr:hover {
    box-shadow: unset;
}

.an-main-area .table tr:after {
    display: none;
}




.scroll-table {
    height: calc(394px - 65px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;

}

.dead-lead-table tr th,
.dead-lead-table tr td {
    text-align: left !important;
}

.scroll-table tfoot {
    background-color: white;
    font-weight: 500;
    display: table;
    table-layout: fixed;
    position: sticky;
    bottom: 0;
}

.scroll-table tfoot tr td {
    background-color: white;
    font-size: 16px;
    font-weight: 500;
}

.scroll-table tfoot tr td:nth-child(1) {
    padding-left: 32px;
    max-width: 210px;
    width: 210px;
}

.pipeline-ineent-table {
    padding: 0 32px;
    margin-bottom: 32px;
}

.an-title .download-btn-group .assigned-drop .dropdown-menu .search-input {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px !important;

}

.an-title .download-btn-group .assigned-drop .dropdown-menu .search-input input {
    width: 100%;
    padding-left: 30px;
    padding-right: 26px;
}

.an-title .download-btn-group .assigned-drop .dropdown-menu .drop-search,
.an-title .download-btn-group .assigned-drop .dropdown-menu .all-clear,
.an-title .download-btn-group .assigned-drop .dropdown-menu .aply-btn {
    margin: 0 8px;
}


.an-title .download-btn-group .assigned-drop .dropdown-menu .dropdown-item+.dropdown-item {
    margin-top: 4px;
}

.an-title .download-btn-group .assigned-drop .dropdown-menu .input-list {
    height: 128px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-left: 8px;
}

.an-title .download-btn-group .assigned-drop .dropdown-menu button.pr-btn {
    display: block;
    width: 100%;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-family: var(--popins);
}



.table-card tbody {
    max-height: 209px;
    display: block;
    width: 100%;
    overflow-y: scroll;
    width: 100%;
    min-height: 203px;
    table-layout: fixed;
    display: table;


}

.table-card table thead,
.table-card table tbody tr,
.table-card table thead,
.table-card table tfoot tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    position: static;
    z-index: 1;

}

.scroll-table .table thead tr th:nth-child(2) {
    position: unset;
    left: unset !important;
}

.scroll-table thead tr {
    box-shadow: 0px 6px 8px rgb(213 213 220 / 20%);
    z-index: 1;
}

.scroll-table tfoot tr {
    box-shadow: 0px -6px 8px rgb(213 213 220 / 20%);
    z-index: 1;
}

.scroll-table::-webkit-scrollbar {
    background-color: white;
}

.record-notfound figure img {
    height: 210px;
}

.booking-eoi-table tbody {
    min-height: 259px;
    max-height: 259px;

}

.an-title .download-btn-group .assigned-drop button:after {
    display: none;
}

.an-title .download-btn-group .assigned-drop .dropdown-menu a.pr-hover:hover {
    color: var(--primary-color);
}

.an-title .download-btn-group .assigned-drop .dropdown-menu a.del-hover:hover {
    color: var(--red);
}

.rdrInputRanges {
    display: none;
}

.owl-nav button {
    position: absolute;
    top: 40%;
    width: 30px;
    height: 30px;
    margin: 0 !important;
}

.owl-nav button.owl-prev {
    left: -15px;
}

.owl-theme .owl-nav [class*='owl-']:hover {
    background-color: transparent !important;
}

.owl-nav button span {
    font-size: 27px !important;
    line-height: 27px;
}

.owl-nav button.owl-next {
    right: -13px;
}



.an-title .download-btn:hover {
    background: #F1F1F5;
    color: var(--black);
}

.an-title .assigned-drop button:hover svg path {
    fill: var(--black) !important;

}

.ded-scroll-table {
    height: 260px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.intent-chart .dead-count h5:before {
    display: none;
}

.intent-chart .dead-count p {
    color: #696974;
    font-size: 18px;
    font-weight: 500;
}



.intent-chart .dead-count h5 {
    padding-left: 0;
}

.intent-chart .dead-count:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 88px;
    width: 4px;
    border-radius: 0px 4px 4px 0px;
    background: rgba(105, 105, 116, 0.6);
}

.intent-chart .dead-count {
    position: relative;
    top: 0;
    bottom: 0;
    padding: 0;
    margin-bottom: 16px;
    padding-left: 15px !important;
    margin-top: 16px;
}

.owl-theme .owl-nav {
    position: absolute;
    left: 0;
    right: 0;
    top: 15px;
}


@media only screen and (max-width: 1400px) {
    /* .an-title .col-xl-2 {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .an-title .col-xl-10 {
        max-width: 100%;
        flex: 0 0 100%;
        margin-top: 12px;
        justify-content: flex-start !important;x
    } */
}



@media only screen and (max-width: 1200px) {
    .an-title .custom-btns-group {
        width: auto;
        flex: 0 0 auto;
    }

    .custom-btns-group {
        width: 496px;
        flex: 0 0 496px;
    }

    .anla-filter-area::-webkit-scrollbar {
        width: 0;
        height: 0;
        display: none;
    }

    .owl-nav button {
        top: 30%;
    }

    .qualification-source-table .col-xl-6+.col-xl-6,
    .pipeline-ineent-table .col-xl-6+.col-xl-6,
    .an-table-area>.row>.col-xl-4,
    .an-table-area .col-xl-9 {
        margin-top: 32px;
    }

    .main-div .an-title .col-xl-10 {
        margin-top: 15px;
    }

    .anla-filter-area {
        width: 100%;
    }

    .source-table,
    .pipeline-ineent-table {
        width: 100%;
    }


    .user-insite-table .table thead tr th:nth-child(2),
    .user-insite-table .table tbody tr td:nth-child(2),
    .user-insite-table .table tfoot tr td:nth-child(2) {
        width: 210px !important;
        min-width: 210px !important;
        max-width: 210px !important;
    }

    .user-insite-table .table thead tr th:nth-child(3),
    .user-insite-table .table tbody tr td:nth-child(3),
    .user-insite-table .table tfoot tr td:nth-child(3) {
        width: 210px !important;
        min-width: 210px !important;
        max-width: 210px !important;
    }

    .user-insite-table .table thead tr th:nth-child(4),
    .user-insite-table .table tbody tr td:nth-child(4),
    .user-insite-table .table tfoot tr td:nth-child(4) {
        width: 300px !important;
        min-width: 300px !important;
        max-width: 300px !important;
    }

    .user-insite-table .table thead tr th:nth-child(5),
    .user-insite-table .table tbody tr td:nth-child(5),
    .user-insite-table .table tfoot tr td:nth-child(5) {
        width: 300px !important;
        min-width: 300px !important;
        max-width: 330px !important;
    }

}

@media only screen and (max-width: 990px) {

    .scroll-table {

        overscroll-behavior: none;

    }

    .map_list ul {
        margin-left: 20px;
    }



    .user-insite-table .table thead tr th:first-child {
        width: 210px !important;
        max-width: 210px !important;
        min-width: 210px !important;
    }

    .anla-filter-area .dropdown {
        position: unset;
    }



    .anla-filter-area::-webkit-scrollbar {
        width: 0;
        height: 0;
        display: none;

    }

    .an-table-area>.row>.col-xl-4 {
        margin-top: 32px;
    }

    .an-table-area .col-xl-9 {
        margin-top: 32px;
    }

    .pipeline-ineent-table .col-xl-6+.col-xl-6 {
        margin-top: 32px;
    }

    .qualification-source-table .col-xl-6+.col-xl-6 {
        margin-top: 32px;
    }

    .custom-btns-group button {
        border: 1px solid #D6D6E0 !important;
        border-radius: 5px !important;
        overflow: hidden;
        display: inline-block;
    }

    /* 
    .custom-btns-group {
        min-width: 190px;
        flex: 0 0 190px;
    } */


    .custom-btns-group {
        border: 0;
    }

    .custom-btns-group {
        margin-right: 20px;
    }

    .custom-btns-group.d-flex.flex-row.align-items-center {
        display: none !important;
    }
}

@media only screen and (max-width: 769px) {
    .main-div:not(.open-sidebar) .an-bords {
        width: 100%;
        flex: 0 0 100%;

    }

    .an-bords {
        transform: translateX(-24px);
    }

    .open-sidebar .an-bords {
        transform: translateX(0px);
    }
}

@media only screen and (max-width: 600px) {
    .map_list>div:nth-child(2) {
        width: 100%;
        padding-left: 16px;
    }

    .user-insite-table .table thead tr th:first-child {
        width: 180px !important;
        max-width: 180px !important;
        min-width: 180px !important;
    }

    .an-title .download-btn-group .assigned-drop button.outline-drop span {
        display: block !important;
    }

    .user-insite-table .table thead tr th:first-child {
        padding-right: 0 !important;
    }


    .user-insite-table .table thead tr th:nth-child(2),
    .user-insite-table .table tbody tr td:nth-child(2),
    .user-insite-table .table tfoot tr td:nth-child(2) {
        width: 210px !important;
        min-width: 210px !important;
        max-width: 210px !important;
    }

    .user-insite-table .table thead tr th:nth-child(3),
    .user-insite-table .table tbody tr td:nth-child(3),
    .user-insite-table .table tfoot tr td:nth-child(3) {
        width: 210px !important;
        min-width: 210px !important;
        max-width: 210px !important;
    }

    .user-insite-table .table thead tr th:nth-child(4),
    .user-insite-table .table tbody tr td:nth-child(4),
    .user-insite-table .table tfoot tr td:nth-child(4) {
        width: 300px !important;
        min-width: 300px !important;
        max-width: 300px !important;
    }

    .user-insite-table .table thead tr th:nth-child(5),
    .user-insite-table .table tbody tr td:nth-child(5),
    .user-insite-table .table tfoot tr td:nth-child(5) {
        width: 300px !important;
        min-width: 300px !important;
        max-width: 330px !important;
    }




    .Employee-Pipeline-Analysis,
    .Employee-Pipeline-table {
        width: 100%;
    }

    .Employee-Pipeline-table:not(.conversion-table) tr th:not(:first-child),
    .Employee-Pipeline-table:not(.conversion-table) tr td :not(:first-child) {
        max-width: 300px !important;
        min-width: 300px !important;
        width: 300px !important;
    }

    .main-div .an-bords {
        width: 100%;
        transform: translateX(0px);
        width: 100%;
    }

    .open-sidebar .an-bords {
        transform: translateX(0);
    }

    .main-div .an-title {
        padding-left: 15px;
        padding-right: 15px;
    }

    .an-title h1.fz24 {
        font-size: 22px;
    }

    .result-box {
        margin-left: 15px;
        margin-right: 15px;
    }

    .an-table-area .col-md-3 {
        margin-bottom: 32px;
    }

    .an-table-area .table-header .col-md-8 {
        margin-bottom: 0px;
    }

    .qualification-source-table {
        padding: 0 15px;
    }

    .Employee-Pipeline-Analysis {
        padding: 0 15px;
    }

    .pipeline-ineent-table {
        padding: 0 15px;
    }


    .an-table-area {
        padding: 0 15px;
    }

    .custom-btns-group button+button {
        display: none;
    }

    .map_list {
        flex-wrap: wrap;
        flex-direction: column;
    }

    .pipeline-ineent-table .col-md-6:nth-child(1),
    .qualification-source-table>.row>.col-md-6:nth-child(1),
    .Employee-Pipeline-Analysis .col-md-3 {
        margin-bottom: 32px;
    }



    /* 
    .custom-btns-group {
        min-width: 190px;
        flex: 0 0 190px;
    } */

    .custom-btns-group {
        margin-right: 20px;
    }

    .rdrMonths {
        flex-wrap: wrap;
    }

    .rdrDefinedRangesWrapper {
        display: none;
    }


    .custom-date-modal .modal-body .rdrDateRangePickerWrapper {
        height: 490px;
        overflow: scroll;
        margin-bottom: 30px !important;
    }

    .custom-btns-group {
        border: none !important;
        border-radius: 0 !important;
    }

    .custom-btns-group button {
        border: 1px solid #D6D6E0 !important;
        border-radius: 5px !important;
        overflow: hidden;
        display: inline-block;
    }

    .anla-filter-area::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
        display: none !important
    }

    .an-table-area>.row>.col-md-4 {
        margin-top: 32px;
    }

    .conversion-table tr td,
    .conversion-table tr th {
        min-width: 230px;
        max-width: 230px;
        width: 230px !important;

    }

    .qualification-source-table .table-card {
        height: unset;
    }

    .map_list ul {
        margin-top: 24px;
        margin-left: auto;
        margin-right: auto;
    }

    .pipeline-ineent-table .table-card {
        height: unset;
    }

    .map_list {
        margin-top: 24px;
    }

    .dead-lead-table .table tbody tr td:first-child {
        width: unset !important;
        max-width: unset !important;
    }

    .user-insite-table .scroll-table tr td:nth-child(1) {

        padding-right: 0 !important;
    }

    .scroll-table .table thead tr th:nth-child(1),
    .scroll-table .table tbody tr td:nth-child(1) {
        width: 160px !important;
        max-width: 160px !important;
        min-width: 160px !important;
        position: sticky !important;
        position: -webkit-sticky !important;
    }

    .scroll-table tfoot tr td:nth-child(1) {
        width: 160px !important;
        max-width: 160px !important;
        min-width: 160px !important;
    }

    .owl-nav {
        position: absolute;
        left: 0;
        right: 0;
        top: 12px;
    }

    .table-card .table thead tr th:nth-child(1),
    .table-card .table tbody tr td:nth-child(1),
    .scroll-table tfoot tr td:nth-child(1) {
        padding-left: 16px !important;
    }

    .table-header {
        padding-left: 16px;
    }


    .result-box .owl-stage .owl-item+.owl-item.active .box {
        padding-left: 25px;
    }

    .map.position-relative {
        margin-left: auto;
        margin-right: auto;
    }

    .pipline-maplist .col-md-5 {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }

    .pipline-maplist .col-md-7 ul {
        margin-top: 0 !important;
    }

    .pipline-maplist .col-md-6:nth-child(1) {
        margin-top: 22px !important;
    }

    .userpipeline .table-header {
        flex-wrap: wrap;
    }


    .userpipeline .table-header .toggle-group button {
        font-size: 12px;
        padding: 4px 11px;
    }

    .userpipeline .table-header .toggle-group {
        margin-top: 8px;
    }

    .table-header h2 {
        font-size: 18px;
    }

    .rdrDateDisplay:after {
        left: 52%;
    }

}