/* .css-t35glz-MenuList>div {
    font-weight: 500 !important;
.view-more .dropdown-item:active {
    color: inherit;
}

span.css-1okebmr-indicatorSeparator {
    display: none;
}

.css-t35glz-MenuList>div {
    font-family: var(--popins) !important;
    color: #44444F !important;
} */
.all-tabs::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.lead-table.all_lead-table.skelton-area {
    overflow: hidden !important;
}

.skelton-area td span {
    height: 20px !important;
}

.skelton-area tr td {
    padding-top: 10px !important;
    padding-bottom: 60px !important;
}

.skelton-area tr td {
    height: 50px !important;
}

@media (max-width: 1400px) {
    .housing-table .def-table {
        height: calc(100vh - 215px);
    }


}

@media (max-width: 1300px) {

    .main-box,
    .name-box,
    .edit-input-modal {
        width: 100% !important;
        max-width: 100% !important;
    }


}

@media (max-width: 1365px) {
    .test_integration {
        max-width: 100%;
        flex: 0 0 100%;
        margin-right: 15px;
    }

    .formmapingmain {

        flex-wrap: wrap;
        gap: 24px
    }

    .form-maping-left {
        order: 2;
    }

    .unmapedform {
        order: 1;
        max-width: 100%;
        flex: 0 0 100%;
    }

}

@media (max-width: 1400px) {
    .learing-center-grid {
        grid-template-columns: repeat(auto-fill, minmax(283px, 1fr));
        margin-right: 10px !important;
    }
}

@media (max-width: 1360px) {
    .learing-center-grid {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        margin-right: 10px !important;
    }
}



@media (max-width: 1200px) {
    .housing-table .def-table {
        height: calc(100vh - 215px);
    }

    .acrtable .def-table {
        height: calc(100vh - 215px);
    }


    .receive-unmapped-forms {
        margin-top: 0;
        left: 0;
    }


    .sidebar:hover {
        overflow: hidden;
    }

    .lead-title h1+p {
        font-size: 12px;
    }

    .recent-activites .sticky-top {
        position: sticky;
        position: -webkit-sticky;
    }

    .lead-table .table tbody tr td:nth-child(2),
    .lead-table .table tbody tr td:nth-child(1) {
        z-index: 2;
    }

    .css-6j8wv5-Input {
        display: none !important;
    }

    .Prepare-content h3 {
        white-space: nowrap !important;
    }

    .assigned-drop>button {
        white-space: nowrap;
    }

    .import-modals.modal-xl {
        max-width: 95% !important;
    }

    .serch-and-filter-blcok {
        margin-right: 0;
    }

    .short-btn>button span,
    .filter-btn>button span {
        display: none;
    }

    .serch-and-filter-blcok .d-flex .mr-12 {
        margin-right: 0;
    }

    .serch-and-filter-blcok .filter-btn.mr-12 {
        margin-right: 0;
    }

    .lead-table .table tbody tr td:nth-child(2),
    .all-leads .all_lead-table .table thead tr th:nth-child(2) {
        left: 63px !important;
    }

    .table tbody tr td:nth-child(1) {
        left: -1px !important
    }

    .main-heading {
        padding-left: 40px !important;
        padding-right: 30px !important;
    }

    .lead-detail-left {
        flex-basis: calc(100% - 360px) !important;
        max-width: calc(100% - 360px) !important;
    }

    .lead-detail-right {
        width: 350px !important;
    }

    .slide-sidebar .edit-textarea p {
        padding-left: 0;
        padding-right: 0;
    }

    .import-modals .modal-dialog {
        max-width: 990px !important;
    }

    .table thead tr th:nth-child(2),
    .table tbody tr td:nth-child(2) {
        left: 64px !important;
    }

    .def-table .table thead tr th:nth-child(1),
    .def-table .table thead tr th:nth-child(2),
    .def-table .table tbody tr td:nth-child(2),
    .def-table .table tbody tr td:nth-child(1) {
        z-index: 1;
    }
}



@media (max-width: 992px) {
    .lead-assignment_table .def-table {
        height: calc(100vh - 248px);
    }

    .test_integration .map-fileds .test_integration_row>div {
        max-width: 100%;
        flex: 0 0 100%;
        width: 100%;
    }

    .test_integration .map-fileds .test_integration_row {
        flex-direction: column;
        flex-wrap: wrap;
        gap: 12px;
        align-items: flex-start;
        border: 0.5px solid #D5D5DC;
        border-radius: 8px;
    }

    .test_integration {
        max-width: 100%;
        flex: 0 0 100%;
        margin-right: 15px;
    }

    .leadMapping .ledmapingrow {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        border: 0.5px solid #D5D5DC;
        border-radius: 8px;
        padding: 16px;
        gap: 12px;
    }

    .leadMapping .forms_fields {
        max-width: 100%;
        flex: 0 0 100%;
        width: 100%;
    }

    .leadMapping .platform_fields {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
    }

    .leadMapping {
        max-width: 100%;
        margin-right: 15px;
    }

    .maphead {
        display: none !important;
    }

    .maphead {
        display: none !important;
    }

    .form-maping-left .forminpuline {
        flex-wrap: wrap;
        flex-direction: column;
        gap: 18px;
        border: 0.5px solid #D5D5DC;
        padding: 14px;
        align-items: flex-start;
        border-radius: 8px;
    }

    .form-maping-left .facebook_forms,
    .form-maping-left .platform_projects,
    .form-maping-left .marketing_agency {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .map-fileds {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 18px;
    }

    .form-maping-left {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .formmapingmain {
        margin-right: 15px;
        gap: 18px;
    }


    .video-cards .card figure img {
        min-height: unset !important;
        max-height: unset !important;
    }

    .tabs #customer .col-4 {
        justify-content: flex-end;
    }

    .tabs #customer .col-xl-4:nth-child(4) .row:nth-child(1) {
        margin-bottom: 4px;
    }

    .an-title .download-btn-group .assigned-drop .dropdown-menu {
        left: 0 !important;
    }

    .company-info {
        padding-right: 24px;
    }

    .rdrDefinedRangesWrapper {
        display: none !important;
    }

    .custom-date-modal .modal-dialog {
        max-width: 703px !important;
    }


    .main-filter .modal-dialog .modal-content {
        height: var(--app-height) !important;
    }



    .merge-del-btn+.serch-and-filter-blcok {
        display: none !important;
    }

    .settings-title>.row>.col-lg-5,
    .settings-title>.row>.col-xl-5 {
        margin-top: 12px;
    }

    .settings-title .form-group {
        width: 100%;
    }

    .assignment-title .form-group {
        margin-top: 12px;
    }

    .assignment-title .form-inline,
    .assignment-title .form-inline .form-group {
        width: 100% !important;
    }

    .user-leads-details .main-section {
        overflow-x: hidden !important;
        overflow-y: scroll !important;
    }

    .lead-detail-left {
        width: 100%;
        height: auto;
        flex-wrap: wrap;
        overflow: visible;
        max-width: 100% !important;
        flex: 0 0 100% !important;
    }

    .lead-detail-right {
        margin-right: 0;
        width: 100% !important;
        margin-top: 24px;
        box-shadow: unset;
    }

    .all-tabs::-webkit-scrollbar {
        display: none;
    }

    .all-tabs {
        max-width: 800px;
        overflow: auto;
    }

    .edit-input-modal .css-1s2u09g-control {
        min-height: 42px !important;
    }

    .fb-innerpageintegratoin .def-table {
        height: calc(100vh - 212px);
    }

}

@media (max-width: 768px) {
    .testleadProcess.modal-sm {
        margin: auto;
    }

    .fb-integration-complete figure {
        margin-top: 20px !important;
        margin-bottom: 18px !important;
    }

    .fb-integration-complete .pr-btn {
        margin-top: 24px;
    }



    .filter-applyed .tags span {
        margin-top: 5px;
    }

    .joinnow {
        opacity: 9 !important;
    }

    .video-cards-main {
        height: calc(100vh - 200px);

    }

    .learing-center-grid {

        column-gap: 15px;
        margin: 0 10px;
    }

    .pipline-maplist {
        margin-top: 50px;
    }

    .custom-mailbox .col-right button {
        margin-left: auto;
        margin-right: 0;
    }

    .display-col-tab-header {
        padding-left: 20px;
    }


    .react-pdf__Page+.react-pdf__Page {
        margin-top: 5px;
    }

    .react-pdf__Document {
        background: black;
    }



    .plush-minus {
        margin-left: 0;
        padding-left: 0;
        border: 0;
    }

    .user-leads-details.open-sidebar:before {
        content: " ";
        position: fixed;
        left: 65px;
        right: 0;
        top: 48px;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: #00000040;
        z-index: 99;
    }

    .user-leads-details .main-section:before {
        display: none;
    }

    .open-sidebar .main-section {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% - 64px) !important;
        width: calc(100% - 64px) !important;
    }

    /* .filter-applyed .tags {
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start !important;
    } */

    .filter-applyed .tags li {
        white-space: nowrap;
        margin-bottom: 8px;
    }

    /* .settings-title .form-group {
        width: auto;
    } */

    .import-for-mobile .card {
        max-width: 350px;
        display: table;
        margin-top: 80px;
    }

    .d-lg-none.import-for-mobile {
        margin-left: auto;
        margin-right: auto;
    }

    .col-right {
        float: right;
        margin-left: auto;
    }

    .custom-mailbox .col-right {
        position: absolute;
        right: 0;
        top: 10px;
    }

    .custom-mailbox {
        position: relative;
    }

    .col-right {
        float: right;
        margin-left: auto;
    }

    .copy-btn {
        max-width: 90px;
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .fb-integration {
        position: relative;
    }

    .lead-title .serch-and-filter-blcok {
        margin-top: 12px;
    }



    .integrations .box {
        padding-bottom: 60px;
    }

    .main-div:not(.open-sidebar) .data-import {
        width: 100%;
        flex: 0 0 100%;
    }

    .data-import {
        width: 100%;
        flex: 0 0 100%;
    }

    .open-sidebar .inport-part {
        overflow: hidden;
    }


    .main-div .main-section {
        width: 100%;
        flex: 0 0 100%;
    }


    .login-page {
        background: var(--primary-color) !important;
    }

    .user-login {
        overflow: hidden;
        height: var(--app-height) !important;


    }

    .sidebar {
        transition: 0.5s all;
        transform: translateX(-65px);
        width: 0;
    }

    .open-sidebar .sidebar {
        transform: translateX(0);
        width: 65px;
        flex: 0 0 65px;
    }

    .main-section,
    .all-pr {
        transform: translateX(-65px);
        width: 100%;
    }

    .open-sidebar .main-section,
    .open-sidebar .all-pr {
        transform: translateX(0);
    }

    .open-sidebar .main-section:before {
        content: " ";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: #00000040;
        z-index: 99;
    }

    .setting-menu {
        z-index: 99;
    }


    .main-section .setting-menu {
        display: none !important;
    }

    .open-sidebar .main-section .setting-menu {
        display: block !important;

        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        ;
    }

    .sidebar ul li a span {
        display: none !important;
    }

    .sidebar {
        height: 100%;
    }

    .open-sidebar .inport-part {
        min-width: 400px;
    }


    .lead-assignment_table .def-table .table tbody tr td:nth-child(2),
    .lead-assignment_table .def-table .table tbody tr td:nth-child(1) {
        z-index: 1;
    }

    .lead-detail-right {
        margin-right: 0 !important;
        width: 100% !important;
        margin-top: 24px;
        box-shadow: unset !important;
    }

    .user-leads-details .main-section {
        overflow-x: hidden !important;
        overflow-y: scroll !important;
    }

    .lead-detail-left {
        width: 100%;
        height: auto !important;
        flex-wrap: wrap !important;
        overflow: visible !important;
        max-width: 100% !important;
        flex: 0 0 100% !important;

    }

    .all-tabs::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    header button {
        margin-right: auto;

    }

    .lead-table .table tbody tr td:last-child,
    .lead-table .table thead tr th:last-child {
        position: static !important;
    }

    .merge-del-btn {
        margin: 12px 0;
    }



    .main-filter .modal-dialog .modal-content {
        height: var(--app-height) !important;
    }

    .pagination-custom .row .col-md-6:nth-child(1) h2 {
        display: none;
    }



    .leadduplication .modal-body h1.fz24 {
        font-size: 22px !important;
    }

    .leadduplication .modal-body a.close {
        right: 15px !important;

        top: 15px !important;
    }

    .tooltip {
        display: none !important;
    }


    .map-fileds .row {
        align-items: center;
        border: 1px solid #D5D5DC;
        border-radius: 6px;
        padding: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        gap: 8px;
    }

    .lead-maping .maphead {
        display: none;
    }

    .map-fileds {
        gap: 12px;
        display: flex;
        flex-direction: column;
    }

    .pl-24.sucess-table {
        padding-left: 15px;
        padding-right: 5px;
    }

    .facebook-integration .pl-24 {
        padding-left: 15px;
    }

    .blankdiv {
        display: none;
    }



    .fb-contect-form {
        max-width: 100%;

    }

    .fb_account_connect .fb_account_select-dropdown {
        max-width: 100%;
    }

    .agency-table .def-table {
        height: calc(100vh - 160px);
    }



    .all-project-table .def-table {
        height: calc(100vh - 204px);
    }



    .housing-table .def-table {
        height: calc(100vh - 228px);
    }

    .lead-assignment_table .def-table {
        height: calc(100vh - 174px);
    }

    .def-table.fb-lead-integration {
        height: calc(100vh - 225px);
    }

    .fb-innerpageintegratoin .def-table {
        height: calc(100vh - 240px);
    }
}

@media (max-width: 600px) {



    #workshops .video-cards,
    #recording .video-cards {
        padding-right: 0px;
    }

    .mt-sm0 {
        margin-top: 0;
    }

    .gap-sm-8 {
        gap: 8px
    }

    .pl-sm-0 {
        padding-left: 0;
    }

    .pr-sm-0 {
        padding-right: 0;
    }

    .mr-sm0 {
        margin-right: 0;
    }

    .pl-sm-12 {
        padding-left: 12px;
    }

    .pr-sm-12 {
        padding-right: 12px;
    }

    .errorEncounted button {
        width: 100%;
    }



    .unprocessed-leads.errormoadl .modal-dialog {
        margin-top: -20px;
    }

    .errorEncounted {
        flex-wrap: wrap;
        gap: 20px;
    }

    .Lctags button.owl-next {
        right: -13px !important;
    }

    .Lctags {
        width: 100%;
        padding-right: 20px;
    }

    .fb-innerpageintegratoin .def-table {
        height: calc(100vh - 350px);
    }



    .housing-table .def-table {
        height: calc(100vh - 340px);
    }

    .lead-assignment_table .def-table {
        height: calc(100vh - 174px);
    }


    .facbook-leads-add .def-table {
        height: calc(100vh - 460px);
    }

    .exprot-table-main .def-table {
        height: calc(100vh - 320px);
    }



    .all-project-table .def-table {
        height: calc(100vh - 292px);
    }

    .agency-table .def-table {
        height: calc(100vh - 280px);
    }



    .lead-assignment_table .def-table {
        height: calc(100vh - 340px);
        padding-bottom: 0;
    }

    .fb-integrationnofound {
        height: calc(100vh - 590px);
    }

    .map-fileds .css-1s2u09g-control {
        min-height: 40px !important;
    }

    .blankdiv {
        height: 10px;

    }

    .leadMapping {
        margin-right: 0;
    }

    .def-table .table tbody tr td:nth-child(2),
    .def-table .table thead tr th:nth-child(2) {
        max-width: unset !important;
        width: unset !important;
    }



    .receive-unmapped-forms button {
        margin-top: 8px;
    }

    .facebook-integration .blankdiv {
        display: none;
    }

    .facebook-integration .footer-modal .secure-for-modal {
        display: none !important;
    }

    .facebook-integration .footer-modal {
        gap: 10px;
        padding-left: 12px;
        padding-right: 12px;
        box-shadow: 0px -4px 8px rgba(23, 23, 37, 0.04);
    }

    .facebook-integration .footer-modal button,
    .facebook-integration .footer-modal a {
        width: 100%;
        margin: 0;
        text-align: center;
    }

    .form_status button {
        width: 100%;
        text-align: center;
        padding: 8px 0;
    }

    .sucess-table .test-btn {
        width: 100%;
        text-align: center;
        padding: 8px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .def-table.fb-lead-integration {
        height: calc(100vh - 310px);
    }




    .map-fileds div#react-select-10-listbox {
        max-height: 150px !important;
        overflow: auto;
    }

    .facebook-integration .footer-modal .secure-for-modal span {
        display: none;
    }

    .receive-unmapped-forms {
        margin-top: 0;
        margin-bottom: 16px;
    }

    .scroll-div {
        padding-left: 0px;
        padding-right: 15px;


    }

    .formmapingmain {
        margin-right: 0;
    }

    .testleadProcess.modal-sm {
        max-width: 90%;
    }

    .map-fileds .row .col-md-6:nth-child(2) {
        padding-left: 15px !important;
    }

    .maping-hader h1 {
        font-size: 14px;
    }

    .maping-hader {
        padding: 12px;
    }

    .fb-leads-add-accoung .form-group .pr-btn {

        justify-content: center;
    }

    .fb-intr-modal .secure-for-modal {
        display: none;
    }

    .table tbody tr td:nth-child(2).max-width-180 {
        max-width: 200px !important;
    }

    .pipline-maplist {
        margin-top: 0px;
    }

    .react-pdf__Page+.react-pdf__Page {
        margin-top: 5px;
    }

    .pdfview .modal-content {
        max-width: 100%;
        overflow: auto;
        max-height: 90vh;

    }

    /* .pdfview .modal-content::-webkit-scrollbar-track {
        background-color: black !important;
    } */


    .bottom-preview p,
    .pdfview button.prev,
    .pdfview button.next {
        display: none;
    }

    .pdfmodal-header h2 {
        max-width: 180px;
    }

    .pdfmodal-header .col-4 {
        padding-right: 0;
    }

    .pdfview .modal-dialog {
        margin: auto !important;
    }

    .pdfmodal-header .close {
        margin-left: 12px !important;
    }

    .pdfmodal-header .row .col-6:nth-child(2) {
        padding-right: 0;
    }

    .react-pdf__Document {
        max-width: 100%;

    }



    .pdfview .modal-content {
        margin-top: 52px !important;
    }

    .filter-applyed+.all-leads .lead-table.shownofound {
        max-height: calc(100vh - 350px) !important;
        min-height: calc(100vh - 350px) !important;
        overflow: scroll;

    }



    .filter-applyed+.all-leads .lead-table.shownofound table+div::-webkit-scrollbar {
        display: none;
    }

    .filter-applyed .tags {
        margin-left: 0 !important;
        max-width: 100% !important;
    }

    .leadsDisplayColumns .filter-sticky {
        padding-right: 10px;
    }

    .leadsDisplayColumns .filter-sticky .close {
        right: 3px;
    }

    .display-col-tab-header .btn-right {
        right: 0px !important;
        z-index: 1;
        width: 24px;
        height: 24px;
        padding: 0;
    }

    .display-col-tab-header .btn-left {
        width: 24px;
        height: 24px;
        padding: 0;
    }

    .display-col-tab-header .btn-left {
        left: -0px !important;

    }

    .display-col-tab-header ul#pills-tab li+li {
        margin-left: 30px;
    }

    .display-col-tab-header ul#column-pills-tab li+li {
        margin-left: 30px;
    }

    .display-col-tab-header {
        margin-top: 22px;
    }

    .display-col-tab-header button.owl-prev {
        opacity: 9 !important;
        left: -30px !important;
    }

    .display-col-tab-header {
        padding-left: 30px;
        padding-right: 30px;
    }

    .display-col-tab-header button.owl-next {
        right: -32px !important;
        opacity: 9 !important;
    }

    .filter-applyed>span {
        margin-top: 0;
    }

    .table tbody tr td.sticky-td,
    .table thead tr th.sticky-th {
        left: -1px !important
    }

    .customision-content a.close {
        top: 26px;
    }

    .CustomiseColumns .modal-content {
        height: 80vh;
        margin-top: -50px;
        background: white;
        overflow: auto;
    }

    .active-avaliabel-row .row .col-sm-6+.col-sm-6 {
        margin-top: 20px;
    }

    .customisecolumnstabs ul#pills-tab,
    .customisecolumnstabs ul#column-pills-tab {
        overflow: auto;
        max-width: 400px;
        flex-wrap: nowrap;
    }


    .card-body .copy-content {
        max-height: unset !important;
        overflow: visible !important;
    }

    .modal-conents .css-1s2u09g-control {
        min-height: 45px !important;
    }



    .dead-lead-dropdown-content div+p {
        padding-right: 18px;
    }

    .skelton-area tr td:nth-child(3)~td {
        display: none;
    }

    .table tbody tr td:nth-child(1) {
        left: 0 !important;
    }

    .filter-applyed .tags span {
        margin-top: 5px;
    }

    .fade.miniTimelineMain.modal-background.modal.show {
        width: 100% !important
    }

    .re-engagedbtn span {
        display: none;
    }

    .enablereengagementmodal p {
        padding: 0 15px !important;
    }

    .assignment-title .btn-toggle {
        flex: 0 0 60px;
        width: 60px !important;
    }

    .edit-input-modal .form-group .col-md-6+.col-md-6 {
        margin-top: 8px;
    }

    .manage-account-title p {
        margin-left: 0;
    }

    .header-top .subscribe-text button {
        margin-left: 8px;
        margin-top: 8px;
    }

    .header-top .subscribe-text {
        flex-wrap: wrap !important;
    }

    .header-top+header+.main-div {
        height: calc(var(--app-height) - var(--header-height) - 60px);
    }

    .header-top+header+.main-div .lead-table {
        padding-bottom: 50px;
    }

    .miniTimelineMain .recent-activites .sticky-top {
        position: sticky !important;
    }

    .table tbody tr:after {
        display: none !important;
    }

    .lead-title .col-md-7>.d-flex {
        width: 100%;
    }

    .lead-title .serch-and-filter-blcok {
        width: 100%;
        margin-right: 0;
    }

    .lead-title .serch-and-filter-blcok .input-active {
        width: 60% !important;
    }

    .lead-title .serch-and-filter-blcok input.searchinput {
        width: 100%;
    }

    .filter .all-fliters .filter-header h2 {
        font-size: 14px;
    }

    .short-btn>button span,
    .filter-btn>button span {
        display: none;
    }

    .short-btn>button svg,
    .filter-btn>button svg {
        margin-right: 0;
    }

    .lead-title h1 {
        margin-bottom: 8px !important;
    }

    /* .main-div.user-leads {
        height: unset !important;
    } */


    .my-login-page .card {
        width: 335px;
        padding: 30px 20px !important;
        max-width: 335px;
        min-height: unset !important;
    }

    .profile-name,
    .profile-icon {
        display: none;
    }


    .user-block .profile-circle {
        margin-right: 0;
    }


    .pagination-custom .row .col-md-6:nth-child(1) h2 {
        display: none;
    }

    .miniTimelineMain .modal-content {
        max-width: 95% !important;
        min-width: 95% !important;
    }

    .tags ul>svg {
        margin-top: 4px;
    }

    .assigned-drop button.outline {
        max-width: 190px;
        white-space: nowrap;
    }



    .merge-del-btn {
        margin: 12px 0;
    }

    .main-filter .modal-dialog {
        max-width: 100% !important;
        margin: 0;
        width: 100% !important;
    }

    .all-tabs {
        max-width: 400px;
        overflow: auto;
    }

    .all-tabs::-webkit-scrollbar {
        display: none !important;
    }

    .video-cards .col-md-3 {
        width: 100%;
        max-width: unset !important;
    }


    .dropdown.ml-auto.asign-del.view-more {
        display: none;
    }


    .latest-followup {
        width: 225px;
    }

    .site-visit {
        width: 230px;
    }

    .followups {
        width: 100%;
        overflow: auto;
        padding-left: 24px;
        padding-right: 24px;
    }


    .tab-content {
        padding-left: 0 !important;
    }

    .lead-detail-right {
        margin-top: 24px;
        box-shadow: unset !important;
    }

    .main-box {
        width: 100% !important;
        max-width: 100% !important;
        border-radius: 4px;
    }

    .name-box {
        width: 100% !important;
        max-width: 100% !important;
    }

    .edit-input-modal {
        width: 100% !important;
        max-width: 100% !important;
    }

    .tabs .tab-content .row .col-md-4+.col-md-4 {
        margin-top: 0px;
    }

    .tabs .tab-content .row {
        margin-bottom: 0 !important;
    }

    .tabs>.row>.col-md-8 {
        min-width: 600px;
        overflow-x: auto;
    }

    .tabs .nav {

        max-width: 570px;
        overflow: scroll;
        display: flex;
        flex-wrap: nowrap;

    }

    .tabs .nav li {
        width: 160px;
        flex: 0 0 160px;
    }

    .tags .form-group {
        display: flex;
        width: 100%;
    }


    .followups.d-inline-flex.flex-fill.px-32.py-24.mt-20.radius-8.justify-content-between {
        flex-direction: column;
    }

    .followups .latest-followup {
        padding-right: 0;
    }

    .followups .latest-followup+.site-visit {
        padding-left: 0 !important;
        margin-top: 34px;
    }


    .lead-detail-left .board .followups>div+div:before {
        display: none;
    }




    header {
        position: sticky;
        top: 0;
        z-index: 99;
    }

    .lead-head {
        padding-top: 20px;
        z-index: 9;
    }

    .recent-activites .sticky-top {
        position: relative;
    }

    .border-0.sidebar-title {
        position: relative !important;
        top: 0 !important;
    }

    .serch-and-filter-blcok .mr-12+.mr-12 {
        margin-right: 0;
    }

    .main-div .main-section.show-new-leads {
        padding: 0 5px;
    }




    .main-div.user-leads-details {
        overflow-x: hidden !important;
        overflow-y: scroll !important;
        padding-bottom: 10px !important;
    }

    .user-leads-details .main-section {
        overflow-x: hidden !important;
        overflow-y: scroll !important;
    }



    .filter-ok-cancel button {
        font-weight: var(--wo-medium);
        min-width: unset !important;

    }

    .filter-ok-cancel {
        gap: 24px;
    }

    .filter-ok-cancel button:nth-child(2) {
        width: 100%;
    }

    .edit-input-text .edit-input input {
        width: 180px !important;
        flex: 0 0 180px !important;
        max-width: 180px !important;
    }

    /* .whatsapp {
    display: none;
} */

    .search-input.edit-input {
        align-items: center;
        display: flex;
    }

    .edit-input-text {
        width: 100% !important;
    }

    .edit-input-text .edit-input .position-relative {
        display: inline;
    }

    .edit-textarea {
        padding: 0 !important;
    }

    .save-cancel-btn {
        justify-content: space-between !important;
    }

    .save-cancel-btn button {
        width: 50% !important;
    }



    .name-pagination .fz24 {
        font-size: 18px;
    }

    .name-pagination .user-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
    }


    .edit-input-text+a {
        display: none;
    }

    .open-sidebar .main-section {
        overflow: hidden !important;
        padding-bottom: 0 !important;
    }

    .tabs .tab-content {

        max-height: unset !important;
    }


    .main-div.user-leads-details.open-sidebar {
        padding-bottom: 0 !important;
    }

    .lead-head {
        padding-top: 0;
    }

    .market-agency .settings-title .col-md-6+.col-md-6 {
        margin-top: 0;
    }

    .show>.btn-secondary.dropdown-toggle {
        background: unset !important;
    }

    .exprot-table-main {
        margin-left: 15px;
    }

    .users .settings-title h1 {
        margin-bottom: 0;
    }

    .urer-table {
        margin-left: 15px;
    }

    .lead-assignment_table {
        margin-left: 15px;
    }

    .roles-table {
        margin-left: 15px;
    }

    .serch-and-filter-blcok .mr-12 {
        margin-right: 0;
    }

    .all-project-table {
        padding-left: 12px;
    }

    .name-pagination {
        margin-right: 0;
    }

    .edit-input-text+img {
        display: none;
    }

    .tabs>.row>.col-md-8::-webkit-scrollbar,
    .tabs .nav::-webkit-scrollbar {
        width: 0;
        opacity: 0;

    }


    .Toastify__toast-container {
        width: 80vw !important;
        padding: 0;
        left: 0;
        margin: 0 auto 20px auto !important;
    }

    .tags ul li {
        margin-bottom: 8px;
    }

    /* 
    .name-pagination button {
        margin-left: -10px !important;
    } */

    .main-filter .modal-dialog .modal-content {
        height: var(--app-height) !important;
    }

    .lead-pagination {
        height: unset !important;
        margin-top: 60px;
    }


    .css-tlfecz-indicatorContainer {
        padding: 4px !important;
    }

    .css-1s2u09g-control {

        min-height: 30px !important;
    }


    .mobile-menu {
        margin-left: -24px;
        margin-bottom: 24px;
    }

    .user-leads .lead-title h1 {
        margin-top: 14px;
    }

    .pagination-custom .css-1s2u09g-control {
        height: 38px !important;
    }

    .pagination-custom ul li {
        white-space: nowrap;
    }

    .uder-table-main {
        height: calc(100vh - 220px) !important;

    }

    .signupform h1 {
        color: var(--white);
        text-align: center;
    }

    .signupform form {
        padding: 0;
    }

    .signupform {
        padding-right: 0 !important;
        padding-bottom: 150px !important;
        padding-left: 0 !important;
    }

    .signupform .form-control:disabled {
        font-size: 14px !important;
        padding: 20px 7px !important;
    }

    .thankyou h1 {
        color: var(--white);
        font-size: 26px;
    }

    .thankyou p {
        color: var(--white);
        text-align: center !important;
        padding-left: 24px;
        padding-right: 24px;
    }


    .table thead tr th:nth-child(1) {
        left: 0 !important;
    }



    .board.position-relative {
        height: unset !important;
        min-height: unset !important;
        padding-bottom: 10px !important;
    }



    .slide-sidebar {
        min-height: unset !important;
        padding-bottom: 40px !important;
        overflow-y: scroll;


    }

    .slide-sidebar .css-1s2u09g-control {
        height: 45px;
    }


    .video-cards-main {
        height: calc(100vh - 372px);
    }

    #guides {
        padding-bottom: 20px;
    }

    .intent-drop>button {
        min-width: 120px !important;
    }

    .lead-detail-right {
        margin-right: 0 !important;
        width: 100% !important;

    }


    .lead-assignment_table .table .form-group {
        width: 290px;
    }

    .assignment-title h1 {
        font-size: 24px;
        margin-right: 0;
    }

    .infobox:hover .infocard {
        opacity: 0 !important;
    }

    .assignment-title button {
        width: 60px !important;
    }

    .assignment-title .form-inline {
        margin-top: 0px;
    }

    .assignment-title .form-inline,
    .assignment-title .form-inline .form-group {
        width: 100% !important;

    }

    .assignment-title .form-group {
        margin-top: 0;
    }

    .assignment-title .searchinput {
        width: 100%;
        max-width: 100% !important;
    }

    .integrations .box {
        max-height: calc(100vh - 260px) !important;
        overflow-x: hidden;
        overflow-y: scroll;
        padding-left: 24px;
        padding-bottom: 20px !important;
    }

    .integrations .box .card {
        margin: 15px auto !important;
    }

    .fb-integration {
        width: 96%;
        max-width: 100% !important;
    }

    .fb-integration span svg {
        margin-left: 0;
    }

    .fb-integration .copy-btn {
        position: absolute;
        right: 6px;
        top: 15px;
    }


    .fb-integration {
        position: relative;
        padding-left: 6px !important;
    }

    .serch-and-filter-blcok .form-inline {
        width: 50%;
        margin-right: 0;
    }

    .serch-and-filter-blcok>.d-flex {
        width: 40%;
        justify-content: space-evenly;
    }



    .table tbody tr td:nth-child(1),
    .table thead tr th:nth-child(1) {
        position: relative !important;
    }

    .table tbody tr td:nth-child(2),
    .table thead tr th:nth-child(2) {
        left: 0px !important;
        max-width: 150px !important;
    }

    .lead-table .table tbody tr td:nth-child(2),
    .all-leads .all_lead-table .table thead tr th:nth-child(2) {
        left: -1px !important;
    }

    .lead-table .table tbody tr td:last-child,
    .lead-table .table thead tr th:last-child {
        position: static !important;
    }

    .main-section.show-new-leads {
        height: unset;
        overflow: hidden;
    }


    .main-div {
        overflow: hidden;
    }

    .short-btn .assigned-drop button {
        max-width: 100% !important;
    }

    .tabs .nav::-webkit-scrollbar {
        display: none !important;
    }


    .lead-detail-right .board {
        padding-left: 0;
    }

    .css-26l3qy-menu {
        z-index: 999 !important;
    }

    .col-right {
        position: absolute;
        right: 0;
        top: 10px;
    }

    .send-mail .card-body .box .row .col-center {
        margin-top: 12px;
    }

    .card-body .box {
        position: relative;
    }

    .acres-heading .col-md-6,
    .landingpage-head .col-md-6,
    .housing-integratoin .col-md-6 {
        margin-top: 14px;
    }


    .user-leads-details .edit-input-text {
        width: auto !important;
    }

    .wigit-for-mobile.d-md-none {
        width: 100%;
        padding-right: 20px;
    }

    .wigit-for-mobile .card {
        border: 0;
        box-shadow: unset;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }

    .wigit-for-mobile .card .card-header {
        background: transparent;
    }

    .wigit-for-mobile {
        padding-top: 24px;
    }

    .wigit-for-mobile .card .card-header[aria-expanded="true"] svg {
        transform: rotate(90deg);
    }

    .wigit-for-mobile .card .card-header svg {
        transition: 0.5s all;
    }



    .user-leads-details .main-section {
        padding-left: 0;
        padding-top: 0;
    }

    .user-leads-details .main-section .name-pagination {
        padding-left: 15px;
        position: sticky;
        top: 0;
        background: #fafafb;
        z-index: 1;
        padding-top: 20px;
        margin-bottom: 0;
        padding-bottom: 20px;
    }

    .lead-detail-right .board {
        padding: 20px 15px;
        margin: 0 15px;
    }

    .name-box button {
        margin-left: auto;
    }

    .learing-center-main .form-group,
    .learing-center-main .form-group input {
        width: 100% !important;
    }

    .learning-center .main-section .lead-head {
        padding-right: 20px !important;
    }

    .learning-center .main-section {
        padding-left: 15px !important;
    }

    .forget-pass .mt-40 {
        margin-top: 26px;
    }

    .fz28 {
        font-size: 26px;
    }

    .fz24 {
        font-size: 22px;
    }

    .fz22 {
        font-size: 20px;
    }

    .fz20 {
        font-size: 18px;
    }

    .fz18 {
        font-size: 16px;
    }

    /* .filter-applyed .tags {
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start !important;
    } */

    .filter-applyed .tags li {
        white-space: nowrap;
        margin-bottom: 8px;
    }

    .learing-center-grid {
        margin-left: 5px !important;

    }

    .unprocessed-leads button.pr-btn {

        padding-left: 8px;
        padding-right: 8px;
    }

    .fb_account_connect .fb_account_select-dropdown {
        max-width: 100%;
    }

    .fb-contect-form {
        padding-right: 0;
    }

    .fb_account_connect {
        gap: 18px;
    }

    .fb_account_connect button {
        width: 100%;
    }
}

@media (max-width: 380px) {


    .filter-applyed+.all-leads .lead-table.shownofound {
        max-height: calc(100vh - 350px) !important;
        min-height: calc(100vh - 350px) !important;
        overflow: scroll;

    }




    /* .filter-applyed .tags {
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start !important;
    } */

    .filter-applyed .tags li {
        white-space: nowrap;
        margin-bottom: 8px;
    }

    .my-login-page .card {
        width: 98% !important;
        max-width: 98% !important;

    }

    .user-leads-details .edit-input-text input {
        width: 190px !important;
    }

    .fb-integration .fz16 {
        width: 200px;
    }

    .assignment-title .btn-toggle {
        width: 80px !important;
    }



    .filter-list .row .col-6:nth-child(even) {
        padding-left: 15px !important;
    }

    .filter-list .col-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .testleadProcess .modal-content {
        padding: 15px !important;
    }

    .filter-applyed .owl-carousel .owl-stage-outer {
        margin-right: 30px;
    }

    .fb-intr-modal .modal-content {
        padding-left: 10px;
        padding-right: 10px;
    }
}


@media (max-width: 340px) {
    .filter-applyed .owl-carousel .owl-stage-outer {
        margin-right: 30px;
    }

    .filter-applyed+.all-leads .lead-table.shownofound {
        max-height: calc(100vh - 350px) !important;
        min-height: calc(100vh - 350px) !important;
        overflow: scroll;

    }

    .filter-applyed+.all-leads .lead-table.shownofound table+div {

        margin-top: 15px;
        overflow-y: scroll;
        justify-content: flex-start !important;

    }

    .learing-center-grid {
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    }

    .video-cards .learing-center-grid .video-time-date {
        font-size: 12px;
    }
}


@media (max-width: 992px) {
    #root {
        overflow: hidden;
    }
}