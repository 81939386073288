.overview-serction>.row>.col:nth-child(1) {
    max-width: calc(100% - 370px);
    flex: 0 0 calc(100% - 370px);

}

.overview-serction>.row>.col:nth-child(2) {
    max-width: 370px;
    flex: 0 0 370px;
    margin-bottom: 0px;
}

.enrol-mdals .modal-dialog .modal-content {
    border: 0 !important;
}

.enrol-mdals .modal-dialog {
    max-width: 650px;
}

.enrol-mdals .modal-dialog .modal-body {
    padding-bottom: 35px;
    padding-top: 48px;
    position: relative;
}



.enrol-mdals .modal-dialog .modal-body .close {
    right: 30px;
    top: 30px !important;
    position: absolute;
}

.overview-serction .over-card {
    margin-top: 20px;
    padding: 24px 48px 40px 48px;
    border-radius: 4px;
    background-color: white;
    box-shadow: 2px 4px 16px rgba(226, 226, 234, 0.6);
    border-radius: 8px;

}

.overview-serction .over-card h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 27px;
    color: var(--black);
}

.overview-serction .card .icon {
    margin-bottom: 14px;
}

.overview-serction .card h5 {
    color: #696974;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--worksans);
}

.overview-serction .card h6 {
    font-family: var(--worksans);
    font-weight: 500;
    margin-bottom: 0;
    color: var(--black);
    font-size: 18px;
}

.over-card .card {
    padding: 24px;
    border: 0;
    background: #F5F9FD;
    box-shadow: unset;
    border-radius: 8px;
}

.wht-include {
    padding: 24px 48px;
    background: white;
    margin-top: 32px;
    border-radius: 8px;
    box-shadow: 2px 4px 16px rgba(226, 226, 234, 0.6);

}

.wht-include h2 {
    font-size: 24px;
    color: var(--black);
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 32px;
}

.wht-include .blocks {
    display: flex;
    align-items: flex-start;
    background-color: white;
    width: unset;
    padding: 0;
    padding-right: 50px;
}

.wht-include .blocks .left-icon {
    margin-right: 32px;
}

.wht-include .blocks h3 {
    color: #44444F;
    font-size: 18px;
    margin-bottom: 4px;
    font-weight: 500;
    line-height: 24px;
}

.wht-include .blocks p {
    font-size: 14px;
    color: #696974;
    line-height: 24px;
    margin-bottom: 0;
    padding-right: 20px;
}

.wht-include .row .col-md-6 {
    margin-bottom: 24px;
}



.overview-serction {
    height: calc(100% - 50px);
    overflow-X: hidden;
    overflow-y: auto;
    padding-bottom: 20px;
}


.what-you-lern {
    margin-top: 32px;
    background: white;
    padding: 24px 48px;
    border-radius: 4px;
    box-shadow: 2px 4px 16px rgba(226, 226, 234, 0.6);
    border-radius: 8px;
}

.what-you-lern h2 {
    font-size: 24px;
    line-height: 38px;
    margin-bottom: 40px;
    font-weight: 500;
    color: #171725;
}

.what-you-lern ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.what-you-lern .c-box {
    display: flex;
    align-items: flex-start;
    color: #171725;
    font-size: 16px;
    padding-right: 50px;
    line-height: 24px;
}

.what-you-lern .row .col-md-6 {
    margin-bottom: 24px;
}

.what-you-lern .c-box svg {
    margin-right: 15px;
    width: 32px;

    flex: 0 0 32px;
    max-width: 32px;
}

.yes {
    margin-top: 32px;
    padding: 44px 48px;
    background: #FFFFFF;
    box-shadow: 2px 4px 16px rgba(226, 226, 234, 0.6);
    border-radius: 8px;
}

.yes h1 {

    font-weight: 500;
    margin-bottom: 24px;
}

.yes .part {
    display: flex;
    padding-right: 62px;
}

.yes .part .left-part {
    margin-right: 16px;
}



.yes .part .right-part h4 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 4px;
    color: var(--black);
    font-weight: 500;
    margin-top: 8px;
}

.yes .part .right-part p {
    font-size: 14px;
    line-height: 24px;
    color: #696974;
    margin-bottom: 0;
}

.yes .part+.part {
    margin-top: 24px;
}

.yes .card {
    padding: 37px 24px 24px;
    background: white;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.yes .card svg {
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 44px;
}

.yes .card h3 {
    font-size: 30px;
    font-weight: 300;
    text-transform: uppercase;
}

.yes .card h3 span {
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
}

.yes .card h5 {
    font-size: 8px;
    color: black;
    text-transform: uppercase;
}

.yes .line {
    display: block;
    width: 100%;
    background: black !important;
    height: 1px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.yes h6 {
    font-size: 16px !important;
    font-weight: normal !important;
    color: #158F61 !important;
    letter-spacing: 0.06em;
    padding: 0 20px;
    margin-bottom: 30px !important;
}

.video-cards .card figure {
    width: 100%;
    height: auto;
    overflow: hidden;

}

.video-cards .card figure img {
    /* object-fit: cover; */
    width: 100%;
    min-height: 200px;
    max-height: 200px;
}

.director {
    margin-top: 32px;
    background: white;
    border-radius: 4px;
    padding: 24px 48px;
    box-shadow: 2px 4px 16px rgba(226, 226, 234, 0.6);
    border-radius: 8px;

}

.director h1 {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: -0.5px;
    color: var(--black);
    margin-bottom: 8px;
}

.director h3 {
    font-size: 16px;
    color: #171725;
    line-height: 24px;
    font-weight: 400;
}

.director p {
    font-size: 16px;
    color: #696974;
    line-height: 1.6;
    margin-bottom: 16px;
}

.faq {

    margin-top: 24px;
    padding: 24px 24px 0px 24px;
}

.faq h1 {
    border-radius: 20px;
    background: var(--white);
    color: var(--black);
    font-size: 36px;
    line-height: 48px;
    letter-spacing: -1px;
    padding: 53px 0;
    font-weight: 500;
    filter: drop-shadow(2px 4px 16px rgba(226, 226, 234, 0.6));

}

.faq .accordion {
    max-width: 770px;
    margin: auto;
    margin-top: -40px;
}

.faq .accordion .card {
    background: white;
    border-radius: 20px !important;
    overflow: hidden;
    border: 0;
    padding: 0 !important;
    max-width: unset;
}

.faq .accordion .card .card-header,
.faq .accordion .card-body {
    background: transparent;
    border: 0;
}

.faq .accordion .card+.card {
    margin-top: 24px;
}

.faq .accordion .card .card-header {
    padding: 24px 30px !important;
    font-size: 16px;
    font-weight: 500;
    font-family: var(--worksans);
}

.faq .accordion .card .card-body {
    padding-top: 0;
    font-size: 16px;
    line-height: 26px;
    color: #696974;
    padding-left: 30px;
    padding-right: 30px;
}

.faq a {
    margin-top: 40px;
    margin-bottom: 60px;
}



.faq .accordion .card .card-header[aria-expanded="true"] .pl {
    opacity: 0;
}


.faq .accordion .card .card-header[aria-expanded="false"] .pl {
    opacity: 9 !important;
}

.faq .accordion .card .card-header[aria-expanded="false"] .close {
    opacity: 0;
}

.faq .accordion .card .card-header img {
    transition: 0.5s all;
    width: 24px;
    opacity: 9;
    position: absolute;
    right: 20px;
    display: none;
}

.faq .accordion .card:hover .collapse {
    display: block !important;
}

.sticky-card {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: white;
    margin-top: 0px;
    padding: 16px 18px;
    box-shadow: 2px 4px 16px rgb(226 226 234 / 60%);
    border-radius: 8px;
    margin-bottom: 0px;

    overflow: hidden;
}

.sticky-card .card-header {
    display: flex;
    background: transparent;
    padding: 0 0 22px 0;

}

.sticky-card .card-header .left {
    width: 80%;
    flex: 0 0 80%;
    padding-right: 24px;
}

.sticky-card .card-header .right .carousel-item {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
}

.sticky-card .card-header .left h3 {
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--black);
    font-family: var(--worksans);
}

.sticky-card .card-header .left p {
    font-size: 12px;
    line-height: 140%;
    color: #696974;
}

.sticky-card .line {
    width: 100%;
    height: 1px;
    background: #E0E0E0;
    margin-top: 16px;
    margin-bottom: 20px;
}

.sticky-card .card-body {
    background: transparent;
    padding: 0;


}

.sticky-card .card-body .price {
    display: flex;
    width: 100%;
    font-size: 28px;
    color: #158F61;
    line-height: 48px;
    margin: 12px 0;
}

.sticky-card .card-body a.pr-btn {
    display: block;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
}

.sticky-card .card-body .price .cut {
    color: #73737E;
    position: relative;
    font-size: 16px;
    font-weight: 100;
}

.sticky-card .card-body .price .cprice {
    margin-right: 20px;
    position: relative;
    font-weight: 600;
}


.sticky-card .card-body .price .cut:before {
    width: 50px;
    height: 2px;
    background: #73737E;
    content: " ";
    position: absolute;
    left: -2px;
    transform: rotate(16.97deg);
    top: 20px;
}

.sticky-card .date {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;
    color: #696974;
    font-weight: 500;
    font-family: var(--worksans);
}

.sticky-card .date .d-itle {
    color: #AAAAAA;
    font-size: 14px;
    margin-bottom: 10px;
}

.sticky-card .date .d-des {
    color: #171725;
    font-size: 14px;
    line-height: 19px;
}

.sticky-card ul {
    margin-top: 20px;
    list-style: none;
    padding: 0;
    max-height: 210px;
    overflow-x: hidden;
    overflow-y: auto;
}

.sticky-card ul li {
    display: flex;
    font-size: 16px;
    color: var(--black);

}

.director p+figure img {
    margin-right: 8px;
}

.sticky-card ul li svg {
    flex: 0 0 24px;
    margin-right: 20px;

}

.sticky-card ul li+li {
    margin-top: 24px;
}

.real-stat-card {
    padding: 24px;
    border-radius: 8px;
    box-shadow: 2px 4px 16px rgba(226, 226, 234, 0.6);
    background-color: white;
}


.real-stat-card h5 {
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    color: var(--black);
    margin-bottom: 16px;
}

.real-stat-card p {
    color: #696974;
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    font-family: var(--worksans);
}

.ele-hover {
    position: absolute;
    opacity: 0;
    z-index: 1;
    background: white;
    padding: 7px 20px;
    font-size: 14px;
    line-height: 22px;
    border-radius: 4px;
    transition: 0.5s all;
    left: 0;
    right: 0;
    display: flex;
    box-shadow: 0px 2px 80px rgb(226 226 234 / 60%);
    top: 57px;
    font-size: 14px;
    transition: 0.5s all;
}

.ele-hover span {
    margin-right: 14px;
}

.hover-btn-elememtns {
    position: relative;
    overflow: hidden;
}

.ele-hover p {
    margin-bottom: 0;
}

.hover-btn-elememtns:hover .ele-hover {
    opacity: 9;
}

.hover-btn-elememtns:hover {
    overflow: visible;
}

.real-stat-card p+p {
    margin-top: 24px;
}

.title-wraper {


    padding: 10px 0;
    margin-bottom: 24px;
}

.title-wraper ul li a:hover svg line {
    stroke: var(--primary-color);
}

.title-wraper ul li a {
    color: #696974;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.title-wraper ul li img {
    height: 20px;
    margin: 0 16px;
}

.title-wraper ul {
    margin-bottom: 0;
}

.timer-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 64px;
    height: 64px;
    background: #F0F6FF;


}

.timer-box span {
    display: block;
    text-align: center;
    line-height: 14.4px;
    font-weight: 400;
}

.timer-box span:nth-child(1) {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.what-you-lern .c-box span {
    margin-top: 5px;
}

@media (min-width: 1480px) {
    .sticky-card ul {
        max-height: unset;
    }
}

@media (max-width: 600px) {
    .overview-serction>.row>.col:nth-child(2) {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .sticky-card {
        position: relative;
        height: unset;
    }

    .overview-serction>.row>.col:nth-child(1) {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .title-wraper {
        display: none;
    }

    .faq .accordion .card .card-header {
        padding-right: 40px !important;
        display: flex;
    }

    .faq .accordion .card .card-header img {
        margin-top: 10px;
    }

    .faq {
        padding: 0;
    }

    .faq .container {
        padding: 0;
    }

    .faq .accordion .card .card-header img {
        margin-top: 0;
    }

    .faq .accordion .card .card-body {
        padding-right: 15px;
    }

    .overview-serction .over-card {
        padding: 24px;
    }

    .over-card .card {
        margin-bottom: 20px;
    }

    .overview-serction .over-card {
        padding: 24px;
    }

    .over-card .card {
        margin-bottom: 20px;
    }

    .wht-include {
        padding: 24px;
    }

    .wht-include .blocks {
        padding-right: 0;
    }

    .wht-include .blocks h3 {
        font-size: 16px;
    }

    .overview-serction .over-card {
        padding: 24px;
    }

    .over-card .card {
        margin-bottom: 20px;
    }

    .wht-include {
        padding: 24px;
    }

    .wht-include .blocks {
        padding-right: 0;
    }



    .what-you-lern {
        padding: 24px;
    }

    .what-you-lern .c-box {
        padding-right: 0;
    }

    .yes {
        padding: 24px;
    }

    .yes .part {
        padding-right: 0;
    }

    .yes .row .col-md-6:nth-child(2) {
        margin-top: 32px;
    }

    .yes h1 {
        margin-bottom: 40px;
    }

    .director {
        padding: 24px;
    }

    .director figure {
        margin-bottom: 18px !important;
    }

    .real-stat-card h5 {
        font-size: 26px;
    }

    .overview-serction {
        height: calc(100% - 60px);
        padding-bottom: 30px;
    }

    .sticky-card ul {
        max-height: unset;
    }

    .director p+figure img {
        margin-bottom: 8px;
    }

    .overview-serction>.row>.col:nth-child(2) {
        display: none;
    }
}